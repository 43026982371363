import React, { Fragment } from 'react'
import requester from '../../requester'

const PIN_LENGTH = 14

export default class CdsBase extends React.Component {
  state = {
    code: '',
    pin: '',
    confirmCodeVisible: false,
    _refreshIndex: 0,
    cdsData: {
      pin: '',
      personFio: '',
      expireAt: '',
      createAt: '',
      isActive: false,
    },
  }

  get canGetCode() {
    if (this.state.code) return true
    return this.state.pin.length === PIN_LENGTH
  }

  componentDidMount() {
    this.refreshCdsData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState._refreshIndex !== this.state._refreshIndex) {
      this.refreshCdsData()
    }
  }

  refreshCdsData() {
    requester.get(`/user/auth`).then(resp => {
      const data = resp.data.cds
      this.setState({ cdsData: data })
    })
  }

  async saveCds() {
    try {
      await requester.post(`/user/cds`, {
        pin: this.state.pin,
        code: this.state.code,
      })
      if (!this.state.code) {
        this.props.showInfo('На почту отравлен код')
        this.setState({ confirmCodeVisible: true })
      } else {
        this.setState({ _refreshIndex: this.state._refreshIndex + 1 })
      }
    } catch (e) {
      throw e
    }
  }

  onChangePin = value => {
    value = String(value)
    value = value.replace(/\D/, '')
    if (value.length > PIN_LENGTH) value = value.substring(0, PIN_LENGTH)
    this.setState({ pin: value })
  }

  onChangeCode = value => {
    value = String(value)
    value = value.replace(/\D/, '')
    this.setState({ code: value })
  }

  render() {
    return <Fragment />
  }
}
