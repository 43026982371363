import { computed, makeObservable } from 'mobx'
import BaseStore from './BaseStore'

export class ProvisionStore extends BaseStore {
  constructor() {
    super()
    makeObservable(this)
  }

  @computed get total_amount() {
    return (
      parseFloat(this.item.quantity || '0') * parseFloat(this.item.price || '0')
    )
  }

  filterDefaults() {
    return { ...super.filterDefaults(), status: '' }
  }

  isReadOnly() {
    return super.isReadOnly()
  }

  @computed get is_read_only_receiver() {
    return (this.is_receiver && !this.is_admin) || this.is_read_only
  }

  @computed get is_admin() {
    return this.access.includes('create')
  }

  @computed
  get is_receiver() {
    return this.access.includes('receive')
  }

  get should_save_filter_keys() {
    return [...super.should_save_filter_keys, 'branch2_id', 'status']
  }

  async postData(pathname, item = this.item) {
    const data = await super.postData(pathname, item)
    if (item.status === 'bought') {
      await this.fetchItem(pathname)
    }
    return data
  }
}

const store = new ProvisionStore()
export default store
