import { inject, observer } from 'mobx-react'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { FaCheckCircle } from 'react-icons/lib/fa'
import { Card, CardBody, CardFooter, CardHeader, Form } from 'reactstrap'
import { CdsBase } from '../../common/components/сds'
import { AutoButton, FormInput, FormLabel } from '../ui/FormInput'

@withTranslation('me')
@inject('store')
@observer
export default class Me extends React.Component {
  constructor(props) {
    super(props)
    this.store = this.props.store.AppStore
    this.store.extendUserInfo()
  }

  get branches() {
    const ui = this.store.user_info
    return ui ? [ui.branch, ...(ui.branches || [])] : []
  }

  get is_valid_pass() {
    const ui = this.store.user_info
    if (!ui) return undefined
    const { new_password, confirm_password } = ui
    return new_password &&
      new_password.length > 3 &&
      new_password === confirm_password
      ? true
      : undefined
  }

  render() {
    const ui = this.store.user_info
    if (!ui) return null
    return (
      <div className='me-page'>
        <Card>
          <CardHeader>
            <b>{this.props.t('profile')}</b>
          </CardHeader>
          <CardBody>
            <h1>{ui.username}</h1>
            <h3 className='text-muted'>
              {this.branches.map(Me.mapName).join(', ')}
            </h3>
            <hr />
            <p>
              <strong>{this.props.t('structure.roles')}:</strong>{' '}
              {ui.roles.map(Me.mapName).join(', ')}
            </p>
            <Form horizontal='true' onSubmit={e => e.preventDefault()}>
              <FormLabel label={this.props.t('structure.data.fio')}>
                <FormInput model={ui.data} name='fio' />
              </FormLabel>
              <hr />
              <FormLabel label={this.props.t('structure.username')}>
                <FormInput
                  model={ui}
                  name='username'
                  type='username'
                  autoComplete='username'
                  readOnly
                  disabled
                />
              </FormLabel>
              <FormLabel label={this.props.t('structure.password')}>
                <FormInput
                  model={ui}
                  name='password'
                  type='password'
                  autoComplete='current-password'
                />
              </FormLabel>
              <FormLabel label={this.props.t('structure.new_password')}>
                <FormInput
                  model={ui}
                  name='new_password'
                  type='password'
                  autoComplete='new-password'
                  valid={this.is_valid_pass}
                />
              </FormLabel>
              <FormLabel label={this.props.t('structure.confirm_password')}>
                <FormInput
                  model={ui}
                  name='confirm_password'
                  type='password'
                  autoComplete='new-password'
                  valid={this.is_valid_pass}
                />
              </FormLabel>
            </Form>
          </CardBody>
          <CardFooter>
            <AutoButton
              onClick={this.store.saveProfileChanges}
              color={'primary'}
            >
              {this.props.t('save')}
            </AutoButton>
          </CardFooter>
        </Card>
        <div style={{ height: 16 }} />
        <Cds
          showInfo={message => this.props.store.AppStore.showInfo(message)}
        />
      </div>
    )
  }

  static mapName(item) {
    return item.name
  }
}

class Cds extends CdsBase {
  render() {
    const { cdsData, code, confirmCodeVisible, pin } = this.state
    return (
      <Card>
        <CardHeader>
          <b>
            ЭЦП {cdsData.isActive && 'получен '}
            {cdsData.isActive && (
              <FaCheckCircle fill={'green'} width={16} height={16} />
            )}
          </b>
        </CardHeader>
        {cdsData.isActive ? (
          <CdsInfoForm cdsData={cdsData} />
        ) : (
          <PinAndCodeForm
            code={code}
            pin={pin}
            onChangePin={this.onChangePin}
            confirmCodeVisible={confirmCodeVisible}
            onChangeCode={this.onChangeCode}
          />
        )}
        {!cdsData.isActive ? (
          <CardFooter>
            <AutoButton
              disabled={!this.canGetCode}
              onClick={() => this.saveCds()}
              color={'primary'}
            >
              {confirmCodeVisible ? 'Подтвердить' : 'Получить код'}
            </AutoButton>
          </CardFooter>
        ) : null}
      </Card>
    )
  }
}

const PinAndCodeForm = ({
  pin,
  code,
  onChangeCode,
  onChangePin,
  confirmCodeVisible,
}) => {
  return (
    <CardBody>
      <FormLabel label={'ИНН'}>
        <FormInput
          value={pin}
          readOnly={confirmCodeVisible}
          onChange={onChangePin}
          valid={pin?.length === 14}
          invalid={pin?.length === 14 ? false : pin?.length !== 0}
        />
      </FormLabel>
      {confirmCodeVisible ? (
        <FormLabel label={'Код подтверждения'}>
          <FormInput value={code} onChange={onChangeCode} />
        </FormLabel>
      ) : null}
    </CardBody>
  )
}

const CdsInfoForm = ({ cdsData }) => {
  return (
    <CardBody>
      <FormLabel label={'Статус сертификата'}>
        <FormInput value={'Активен'} readOnly />
      </FormLabel>
      <FormLabel label={'ИНН'}>
        <FormInput value={cdsData.pin} readOnly />
      </FormLabel>
      <FormLabel label={'ФИО'}>
        <FormInput value={cdsData.personFio} readOnly />
      </FormLabel>
      <FormLabel label={'Дата создание сертификата'}>
        <FormInput value={cdsData.createAt} readOnly />
      </FormLabel>
      <FormLabel label={'Срок действия сертификата'}>
        <FormInput value={cdsData.expireAt} readOnly />
      </FormLabel>
    </CardBody>
  )
}
