import { action, computed, makeObservable } from 'mobx'
import moment from 'moment/moment'
import { SelectionStore } from './SelectionStore'

export const ROUTE_LIST_STATUS = {
  COMPLETED: 'completed',
  REJECTED: 'rejected',
  DONE: 'done',
  SAVED: 'saved',
  PROCESSED: 'processed',
}

export const ROUTE_LIST_STATUS_NAME = {
  [ROUTE_LIST_STATUS.COMPLETED]: 'Завершено',
  [ROUTE_LIST_STATUS.PROCESSED]: 'Обрабатон',
  [ROUTE_LIST_STATUS.REJECTED]: 'Отклонено',
  [ROUTE_LIST_STATUS.DONE]: 'Принят',
  [ROUTE_LIST_STATUS.SAVED]: 'Сохранено',
}

export const ROUTE_LIST_STATUS_COLOR = {
  [ROUTE_LIST_STATUS.COMPLETED]: 'primary',
  [ROUTE_LIST_STATUS.PROCESSED]: 'primary',
  [ROUTE_LIST_STATUS.REJECTED]: 'danger',
  [ROUTE_LIST_STATUS.DONE]: 'success',
  [ROUTE_LIST_STATUS.SAVED]: 'black',
}

export const ROUTE_LIST_STATUS_COLOR_WEB = {
  [ROUTE_LIST_STATUS.COMPLETED]: 'text-primary',
  [ROUTE_LIST_STATUS.PROCESSED]: 'text-primary',
  [ROUTE_LIST_STATUS.REJECTED]: 'text-danger',
  [ROUTE_LIST_STATUS.DONE]: 'text-success',
  [ROUTE_LIST_STATUS.SAVED]: 'text-black',
}

export class RoutelistStore extends SelectionStore {
  constructor() {
    super()
    makeObservable(this)
  }

  isReadOnly() {
    return this.item?.data?.status === 'closed' || super.isReadOnly()
  }

  @computed get difference_value() {
    return this.item.data?.end_value - this.item.data?.start_value
  }

  @computed get total_difference_value() {
    return this.items.reduce((sum, i) => {
      return (
        sum +
        (i.data.difference_value !== undefined ? i.data.difference_value : 0)
      )
    }, 0)
  }

  @computed get fuel_consumed() {
    return (
      ((this.item.data?.end_value - this.item.data?.start_value) / 100) *
        parseFloat(this.item.data?.standard_fuel_consumption) || 0
    )
  }

  @computed get current_fuel_balance() {
    const start_fuel_balance = this.item.data?.start_fuel_balance
      ? this.item.data?.start_fuel_balance
      : this.item.start_fuel_balance
      ? this.item.start_fuel_balance
      : this.item.data?.user_last_fuel_balance
    return (
      parseFloat(start_fuel_balance) +
      parseFloat(this.item.data?.filled_by_card || '0') -
      this.fuel_consumed
    )
  }

  onDone() {
    const start_fuel_balance = this.item.data?.start_fuel_balance
      ? this.item.data?.start_fuel_balance
      : this.item.start_fuel_balance
      ? this.item.start_fuel_balance
      : this.item.data?.user_last_fuel_balance
    this.item.data.end_fuel_balance = this.current_fuel_balance
    this.item.data.start_fuel_balance = start_fuel_balance
    this.item.data.fuel_consumed = this.fuel_consumed
    this.item.data.difference_value = this.difference_value
  }

  @action
  setStatus(status) {
    if (!this.item.data) return
    if (
      status === ROUTE_LIST_STATUS.DONE ||
      status === ROUTE_LIST_STATUS.PROCESSED
    ) {
      this.item.data.status = status
      this.onDone()
    } else if (status === ROUTE_LIST_STATUS.COMPLETED) {
      this.item.end_date = moment().format('YYYY-MM-DD HH:mm:ss')
      this.item.data.status = status
    } else {
      this.item.data.status = status
    }
  }
}

const store = new RoutelistStore()
export default store
